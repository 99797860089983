<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
        <dialog-message
          :dialog_message="dialog_message"
          :message="message"
        ></dialog-message>
      <v-col cols="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">{{ $t('title.UserProfile') }}</div>
          </template>
          <v-col class="text-right">
            <v-dialog v-model="dialog_password" max-width="500px" @click:outside="closePassword">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">
                  <v-icon left>mdi-pencil-plus</v-icon>
                  {{ $t("user.changePassword") }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ $t("user.changePassword") }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row dense>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="item.username"
                          :label="$t('user_mgr.username')"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="item.nickname"
                          :label="$t('user_mgr.nickname')"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="password.password1"
                          :type="showPassword ? 'text' : 'password'"
                          prepend-icon="mdi-lock"
                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="showPassword = !showPassword"
                          :label="$t('user_mgr.resetPassword1')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="password.password2"
                          :type="showPassword ? 'text' : 'password'"
                          prepend-icon="mdi-lock"
                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="showPassword = !showPassword"
                          :label="$t('user_mgr.resetPassword2')"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closePassword">
                    {{
                    $t("common.cancel")
                    }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="resetPassword">
                    {{
                    $t("user_mgr.resetPassword")
                    }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field :label="$t('user_mgr.username')" v-model="item.username" disabled />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    class="purple-input"
                    :label="$t('user_mgr.first_name')"
                    v-model="item.first_name"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    class="purple-input"
                    :label="$t('user_mgr.nickname')"
                    v-model="item.nickname"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    class="purple-input"
                    :label="$t('user_mgr.email')"
                    v-model="item.email"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    class="purple-input"
                    :label="$t('user_mgr.phone')"
                    v-model="item.phone"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    class="purple-input"
                    :label="$t('user_mgr.line_account')"
                    v-model="item.line_account"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    class="purple-input"
                    :label="$t('user_mgr.company_name')"
                    v-model="item.company_name"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    class="purple-input"
                    :label="$t('user_mgr.job_title')"
                    v-model="item.job_title"
                  />
                </v-col>

                <!-- <v-col cols="12">
                  <v-text-field
                    class="purple-input"
                    :label="$t('user_mgr.expiredTime')"
                    v-model="item.expired_time"
                    disabled
                  />
                </v-col> -->

                <v-col cols="12" class="text-right">
                  <v-btn color="success" class="mr-0" @click="update">{{ $t('common.save') }}</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data: vm => ({
    dialog_message: false,
    loading: false,
    dialog_password: false,
    showPassword: false,
    item: {
      username: "",
      first_name: "",
      nickname: "",
      email: "",
      phone: "",
      expired_time: "",
      line_account: "",
      company_name: "",
      job_title: ""
    },
    password: {
      password1: "",
      password2: ""
    },
    message: {
      title: "",
      text: ""
    },
  }),
  computed: {
    ...mapState(["token"])
  },
  methods: {
    ...mapMutations(["setTitle", "setUsername"]),
    closePassword() {
      this.dialog_password = false;
      this.loading = false;
    },
    getApi() {
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/user/getProfile/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        }
      };

      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = currentObj.$t(error.response.data.detail);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.item = data;
      this.userId = this.item.id;
      this.item.expired_time = this.TransDate(
        this.item.expired_time
      );
    },
    checkPassword(password1, password2) {
      let is_valid = true;
      if (password1 != password2) {
        alert(this.$i18n.t("user_mgr.passwordNotMatch"));
        is_valid = false;
      } else if (password1 == "") {
        alert(this.$i18n.t("user_mgr.missingPassword"));
        is_valid = false;
      }
      return is_valid;
    },
    checkData(action) {
      let is_valid = true;
      [this.item.nickname, this.item.phone, this.item.email].every(
        (Element, index) => {
          if (!is_valid) {
            return false;
          }
          let alertWord = "user_mgr.missing";
          if (Element == "") {
            switch (index) {
              case 0:
                alertWord += "Nickname";
                break;
              case 1:
                alertWord += "Phone";
                break;
              case 2:
                alertWord += "Email";
                break;
            }
            alert(this.$i18n.t(alertWord));
            is_valid = false;
          }
          return true;
        }
      );
      return is_valid;
    },
    update() {
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/user/" + this.userId + "/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token
        }
      };
      let data_valid = this.checkData("update");
      if (!data_valid) {
        this.loading = false;
        return;
      }
      let data = {
        email: this.item.email,
        first_name: this.item.first_name,
        nickname: this.item.nickname,
        phone: this.item.phone,
        line_account: this.item.line_account,
        company_name: this.item.company_name,
        job_title: this.item.job_title
      };
      this.axios
        .patch(url, data, config)
        .then(function(response) {
          currentObj.loading = false;
          alert("送出成功");
          currentObj.getApi();
          currentObj.setUsername(currentObj.item.nickname);
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("update-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    resetPassword() {
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/user/" + this.userId + "/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token
        }
      };
      let data_valid = this.checkPassword(
        this.password.password1,
        this.password.password2
      );
      if (!data_valid) {
        this.loading = false;
        return;
      }
      let data = {
        password: this.password.password1
      };
      this.axios
        .patch(url, data, config)
        .then(function(response) {
          currentObj.loading = false;
          alert("送出成功");
          currentObj.getApi();
          currentObj.closePassword();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("reset-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    }
  },
  mounted() {
    this.setTitle(this.$i18n.t("title.UserProfile"));
    this.getApi();
  }
  //
};
</script>
